<script>
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page_header_new.vue";
import appConfig from "@/app.config.json";
import axios from "axios";

/**
 * Starter-page component
 */
export default {
  page: {
    title: "Starter Page",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Starter Page",
      items: [
        {
          text: "Utility",
        },
        {
          text: "Starter Page",
          active: true,
        },
      ],

      list: [],
      output: null
    };
  },
  components: {
    Layout,
    PageHeader,
  },

  mounted() {
    this.getAddr()
  },

  methods: {
    getAddr() {
      let url = '/api/device/delivery/addr/1562'

      axios.get(url)
          .then((res) => {
            console.log('GET Addr', res.data)
            this.list = res.data
          })
          .catch(
              err => console.log('err :', err)
          )
    },

    async printAct() {
      const el = this.$refs.testPrint

      const options = {
        type: 'dataURL'
      }

      this.output = await this.$html2canvas(el, options)
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="card">
      <div class="card-body">
        <h5>TEST</h5>
        <hr/>

        <div ref="testPrint">
          <table>
            <th>주소</th>
            <th>지번</th>
            <th>도로</th>
            <th>여부</th>

            <tr v-for="(row, idx) in list" :key="idx">
              <td>{{ row.delivery_addr_srl }}</td>
              <td>{{ row.jibunaddr }}</td>
              <td>{{ row.roadaddr }}</td>
              <td>{{ row.choi }}</td>
            </tr>
          </table>
        </div>

      </div>

      <img :src="output" style="border: 2px #03314D solid; display: flex; justify-content: center; align-items: center">

      <div class="card-footer">
        <div @click="printAct">
          이미지 생성하기
        </div>
      </div>
    </div>
  </Layout>
</template>
